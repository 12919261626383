<template>
  <div class="deposit">
    <SimpleHeader></SimpleHeader>
    <div class="ml">
      <el-button type="text" class="el-icon-back fs16" @click="$router.go(-1)"> {{ $t(207) }}</el-button>
    </div>
    <div class="fund-box">
      <h3 class="title">{{ $t(190) }}</h3>
      <div class="flex">
        <div class="left-box">
        <div class="item coin-select">
          <div class="label mb5">{{ $t(196) }}</div>
          <img class="curCoinLogo" :src="coinMsg[coin]?coinMsg[coin].logoUri:''" alt="" style="margin-right: 10px;">
          <el-select v-model="coin" class="coin-input">
            <el-option
              v-for="item in walletGroups"
              :key="item.coin"
              :value="item.coin"
              :label="item.coin">
              <div class="flex align-center">
                <img :src="coinMsg[item.coin]?coinMsg[item.coin].logoUri:''" alt="" style="width: 24px;height:24px;margin-right: 10px;">
                <span>{{ item.coin }}</span>
              </div>
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <div class="label mb5">{{ $t(230) }}</div>
          <div class="tab">
            <div class="item"
              v-for="item in chains"
              :key="item.id"
              :class="{active: item.id===curWallet.id}"
              @click="curWallet=item">{{ item.chain }}</div>
          </div>
        </div>
        <div class="item">
          <div class="label mb5">{{ $t(200) }} {{coin}}</div>
          <div class="qr text-center">
            <img :src="curWallet.qrcode" alt="" style="width: 120px;height:120px;margin: 18px 0px">
            <p v-if="curWallet.memo">{{ curWallet.memo }}</p>
            <p>{{ curWallet.address }}</p>
            <el-button type="text" class="el-icon-document-copy" @click="copyAddress">{{ $t(205) }}</el-button>
          </div>
        </div>
      </div>
        <el-form
          class="flex1"
          ref="form"
          :rules="rules"
          :model="form">
          <div class="item">
            <div class="label mb5">{{ $t(202) }}</div>
            <el-form-item prop="amount">
              <el-input v-model="form.amount"></el-input>
            </el-form-item>
          </div>
          <div class="item" v-if="isShowDepositAddrWithSpot">
            <div class="label mb5">{{ $t(200) }}</div>
            <el-form-item prop="address">
              <el-input v-model="form.address"></el-input>
            </el-form-item>
          </div>
          <div class="item">
            <div class="label mb5 cursor flex align-center" @click="hashExpand=!hashExpand">Hash({{ $t(208) }})<i class="icon cursor" :class="{'el-icon-caret-bottom': !hashExpand,'el-icon-caret-top':hashExpand}"></i> </div>
            <el-form-item
              v-show="hashExpand"
              prop="transferHash">
                <el-input v-model="form.transferHash"></el-input>  
            </el-form-item>
          </div>
          <div class="item" v-if="isOpenDepositPicUpload">
            <div class="label mb5">{{ $t(203) }}</div>
            <el-form-item prop="certificateUri">
              <ImgUploader v-model="form.certificateUri" isCommon :width="200" :height="200"></ImgUploader>
            </el-form-item>
          </div>
          <div class="item">
            <div class="label mb5 cursor flex align-center" @click="remarkExpand=!remarkExpand">{{ $t(204) }} ({{ $t(208) }})<i class="icon cursor" :class="{'el-icon-caret-bottom': !remarkExpand,'el-icon-caret-top':remarkExpand}"></i> </div>
            <el-form-item
              v-show="remarkExpand"
              prop="remark">
                <el-input v-model="form.remark"></el-input>  
            </el-form-item>
          </div>
          
          <div class="pt20 pb10"><el-button type="primary" @click="doSubmit" :loading="loading">{{ $t(177) }}</el-button></div>
        </el-form>
      </div>
    </div>
    <SimpleFooter></SimpleFooter>
  </div>
</template>

<script>
import SimpleHeader from '@/components/SimpleHeader'
import SimpleFooter from '@/components/SimpleFooter'
import ImgUploader from '@/components/ImgUploader'
import { getDepositChannels, deposit } from '@/api'
import utils from '@/utils/misc'
import {findIndex} from '@/utils/misc'
export default {
  name: 'Deposit',
  components: { SimpleHeader, SimpleFooter, ImgUploader },
  mixins: [],
  data() {
    return {
      coin: '',
      wallets: [],
      curWallet: '',
      loading:false,
      remarkExpand: false,
      hashExpand: true,
      form: {
        walletId: '',
        address: '',
        amount: '',
        remark: '',
        certificateUri: '',
        transferHash: ''
      }
    }
  },
  watch: {
    chains(val) {
      if (val) {
        this.curWallet = val[0]
      }
    }
  },
  computed: {
    coinMsg() {
      return this.$store.state.coins
    },
    configObj() {
      return window.configObj
    },
    isShowDepositAddrWithSpot() {
      return window.configObj.isShowDepositAddrWithSpot === '1'
    },
    isOpenDepositPicUpload() {
      return window.configObj.isOpenDepositPicUpload === '1'
    },
    countryList() {
      return this.$store.state.countryList
    },
    walletGroups() {
      const obj = {}
      for (const item of this.wallets) {
        if (obj[item.coin]) {
          obj[item.coin].wallets.push(item)
        } else {
          obj[item.coin] = {
            coin: item.coin,
            wallets: [item]
          }
        }
      }
      return obj
    },
    chains() {
      return this.walletGroups[this.coin] ? this.walletGroups[this.coin].wallets : []
    },
    rules() {
      return {
        amount:[{
          validator: (rule, value, callback) => {
            if (value === '') {
              return callback(new Error(this.$t(209)))
            }
            if (isNaN(value) || +value <= 0 ) {
              return callback(new Error(this.$t(210)))
            }
            callback()
          },
          trigger: 'blur'
        }],
        address:[{
          validator: (rule, value, callback) => {
            if (value === '') {
              return callback(new Error(this.$t(211)))
            }
            if (!/^\w+$/.test(value)) {
              return callback(new Error(this.$t(332)))
            }
            if (value.length < 32) {
              return callback(new Error(this.$t(231)))
            }
            callback()
          },
          trigger: 'blur'
        }],
        certificateUri:[{
          validator: (rule, value, callback) => {
            if (value === '') {
              return callback(new Error(this.$t(212)))
            }
            callback()
          }
        }]
      }
    }
  },
  beforeMount() {
    getDepositChannels().then(res => {
      this.wallets = res.addresses || []
      if (this.wallets.length) {
        this.coin = this.wallets[0].coin
      }
    })
  },
  methods: {
    copyAddress() {
      this.$copyText(this.curWallet.address).then(res => {
        this.showSuccessMsg(this.$t(206))
      })
    },
    doSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          const obj = {...this.form, amount: +this.form.amount, walletId: this.curWallet.id}
          if (!this.isShowDepositAddrWithSpot) {
            delete obj.address
          }
          if (!this.isOpenDepositPicUpload) {
            delete obj.certificateUri
          }
          deposit(obj).then(res => {
            this.showSuccessMsg(this.$t(224))
            this.$store.dispatch('getUserAssets')
            this.$router.go(-1)
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .ml{
    width: 1000px;
    margin: 0 auto;
  }
  .fund-box{
    color: #1E2329;
    width: 1000px;
    border-radius: 8px;
    margin: 5vh auto;
    background: #fff;
    padding: 40px;
    .title{
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 20px;
    }
    .tab{
      margin: 10px 0px 20px 0px;
      color: #707A8A;
      font-size: 16px;
      .item{
        display: inline-block;
        cursor: pointer;
        padding: 8px 24px;
        margin-right: 12px;
        &.active{
          background-color: #F5F5F5;
          color: #1E2329;
          border-radius: 8px;
        }
      }
    }
    .el-input__inner{
      height: 46px;
      line-height: 46px;
    }
    .left-box{
      width: 500px;
      margin-right: 40px;
      &>.item{
        margin-bottom: 24px;
      }
      .qr{
        padding: 20px;
        border: 1px solid $borderColor;
        border-radius: 4px;
      }
    }
    .coin-select{
      position: relative;
      .curCoinLogo{
        position: absolute;
        left: 10px;
        top: 28px;
        height: 24px;
        width: 24px;
        z-index:99;
      }
    }
  }
</style>
<style lang="scss">
.coin-input {
  .el-input__inner{
    padding-left: 40px;
  }
}
</style>